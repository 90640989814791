import moment from "moment";

export default class ImageUtil {
    // Sas token is valid for 2 hours in BE, to avoid timing issues we keep a margin of 5 minutes
    private static readonly sasTokenExpirationTimeInMinutes: number = 115;

    public static hasSasTokenExpired(expirationDate: Date): boolean {
        const now = moment().toDate();
        return expirationDate <= now;
    }

    public static getSasTokenExpirationDate(): Date {
        return moment().add(this.sasTokenExpirationTimeInMinutes, "minutes").toDate();
    }
}
