import PlaceholderChatImage from "images/placeholder-chat-image.svg";
import { Component } from "react";

import DateUtils from "../../../utils/dateUtils";
import IChatInterfaceMessageProps from "../interfaces/IChatInterfaceMessageProps";
import IChatInterfaceMessageState from "../interfaces/IChatInterfaceMessageState";
import AttachmentViewer from "./attachmentViewer";

export default class ChatInterfaceMessage extends Component<IChatInterfaceMessageProps, IChatInterfaceMessageState> {
  public constructor(props: IChatInterfaceMessageProps) {
    super(props);
    const state: IChatInterfaceMessageState = {
      showImageFullscreen: false,
    };

    this.state = state;

    this.toggleImageFullScreen = this.toggleImageFullScreen.bind(this);
    this.onOpenAttachment = this.onOpenAttachment.bind(this);
  }

  private get messageClassName(): string {
    const defaultClass = "g-chat-interface-message";
    return this.props.isFromCurrentUser ? defaultClass + " g-my-message" : defaultClass;
  }

  private toggleImageFullScreen(): void {
    this.setState({
      showImageFullscreen: !this.state.showImageFullscreen,
    });
  }

  private onOpenAttachment(): void {
    if (this.props.onOpenAttachment) {
      this.props.onOpenAttachment();
    }

    this.toggleImageFullScreen();
  }

  public render(): JSX.Element {
    return (
      <div className={this.messageClassName}>
        {!this.props.isFromCurrentUser && <div className="g-chat-interface-message-title">{this.props.senderName}</div>}

        {this.props.onOpenAttachment && (
          <div className="g-chat-interface-message-attachment">
            <img src={PlaceholderChatImage} alt="Open attachment" onClick={this.onOpenAttachment} />
          </div>
        )}

        {this.props.messageContent && (
          <div className="g-chat-interface-message-content">{this.props.messageContent}</div>
        )}

        <div className="g-chat-interface-message-timestamp">{DateUtils.getFriendlyDateTimeString(this.props.date)}</div>

        {this.props.attachmentUri && this.props.onOpenAttachment && (
          <AttachmentViewer
            imageUri={this.props.attachmentUri}
            onClose={this.toggleImageFullScreen}
            showImage={this.state.showImageFullscreen}
          />
        )}
      </div>
    );
  }
}
