import { ChatType } from "enums/chatType";
import TranslationMapper from "i18n/mapper";
import i18next from "i18next";
import IChatMessage from "interfaces/IChatMessage";
import IChatDeletionCheckDetails from "pages/avgGdprManagement/interfaces/IChatDeletionCheckDetails";
import LanguageProvider from "providers/languageProvider";
import { NotificationManager } from "react-notifications";
import ImageUtil from "utils/imageUtil";

import IChatInfo from "../../interfaces/IChatInfo";
import ChatService from "../../services/chatService";
import TelemetryService from "../../services/telemetryService";
import { actionCreator, AppAction } from "../../store/appAction";
import { ActionTypes } from "../actionTypes";

export const updateChatInfo = actionCreator<ActionTypes.UPDATE_CHAT_INFO, IChatInfo[] | undefined>(
  ActionTypes.UPDATE_CHAT_INFO
);
export const updateChatDeletionCheckDetails = actionCreator<
  ActionTypes.FETCHED_CHAT_DELETION_DETAILS,
  IChatDeletionCheckDetails | undefined
>(ActionTypes.FETCHED_CHAT_DELETION_DETAILS);
export const updateChatConversation = actionCreator<ActionTypes.UPDATE_CHAT_CONVERSATION, IChatMessage[]>(
  ActionTypes.UPDATE_CHAT_CONVERSATION
);
export const updateChatMessage = actionCreator<ActionTypes.UPDATE_CHAT_MESSAGE, IChatMessage>(
  ActionTypes.UPDATE_CHAT_MESSAGE
);
export const updateChat = actionCreator<ActionTypes.UPDATE_CHAT, IChatMessage>(ActionTypes.UPDATE_CHAT);
export const updateTopicId = actionCreator<ActionTypes.UPDATE_TOPIC_ID, string | undefined>(
  ActionTypes.UPDATE_TOPIC_ID
);
export const setChatLoading = actionCreator<ActionTypes.UPDATE_LOADING_CHAT, boolean>(ActionTypes.UPDATE_LOADING_CHAT);
export const setChatsLoading = actionCreator<ActionTypes.UPDATE_LOADING_CHATS, boolean>(
  ActionTypes.UPDATE_LOADING_CHATS
);
export const setChatsDeletionLoading = actionCreator<ActionTypes.UPDATE_LOADING_CHATS_DELETION, boolean>(
  ActionTypes.UPDATE_LOADING_CHATS_DELETION
);

export function fetchEndUserChatInfo(): AppAction {
  return (dispatch): void => {
    const chatService = new ChatService();

    dispatch(toggleChatsLoadingStatus(true));
    chatService
      .getEndUsersWithChatInfoAsync()
      .then((chats: IChatInfo[]) => {
        dispatch(updateChatInfo(chats));
      })
      .catch((error) => {
        dispatch(updateChatInfo(undefined));
        NotificationManager.error(LanguageProvider.t(TranslationMapper.global.errors.fetch_chat_error));
        TelemetryService.AppInsights?.trackException({
          exception: {
            name: "ChatActions.fetchEndUserOverview",
            message: `Error on fetch EndUser overview ${error}`,
          },
        });
      })
      .finally(() => dispatch(toggleChatsLoadingStatus(false)));
  };
}

export function fetchExecutorsChatInfo(): AppAction {
  return (dispatch): void => {
    const chatService = new ChatService();

    dispatch(toggleChatsLoadingStatus(true));
    chatService
      .getExecutorsWithChatInfoAsync()
      .then((chats: IChatInfo[]) => {
        const updatedChats: IChatInfo[] = chats.map((x) => ({
          ...x,
          lastMessageSentDate:
            x.lastMessageSentDate instanceof Date ? x.lastMessageSentDate.toISOString() : x.lastMessageSentDate,
        }));

        dispatch(updateChatInfo(updatedChats));
      })
      .catch((error) => {
        dispatch(updateChatInfo(undefined));
        NotificationManager.error(LanguageProvider.t(TranslationMapper.global.errors.fetch_chat_error));
        TelemetryService.AppInsights?.trackException({
          exception: {
            name: "ChatActions.fetchEndUserOverview",
            message: `Error on fetch EndUser overview ${error}`,
          },
        });
      })
      .finally(() => dispatch(toggleChatsLoadingStatus(false)));
  };
}

export function fetchChat(topicId: string, chatType: ChatType): AppAction {
  return (dispatch): void => {
    const chatService = new ChatService();

    dispatch(toggleChatLoadingStatus(true));

    chatService
      .getChatMessagesForTopicAsync(topicId, chatType)
      .then((chatConversation: IChatMessage[]) => {
        dispatch(updateChatConversation(chatConversation));
      })
      .catch((error) => {
        NotificationManager.error(LanguageProvider.t(TranslationMapper.global.errors.fetch_chat_error));
        TelemetryService.AppInsights?.trackException({
          exception: {
            name: "ChatActions.fetchChat",
            message: `Error on fetch chat ${error}`,
          },
        });
      })
      .finally(() => dispatch(toggleChatLoadingStatus(false)));
  };
}

export function sendChat(topicId: string, contents: string, chatType: ChatType): AppAction {
  return (dispatch): void => {
    const chatService = new ChatService();
    dispatch(toggleChatLoadingStatus(true));
    chatService
      .sendChatMessageAsync(topicId, contents, chatType)
      // TODO 2131: Temporary until signalR is implemented for serviceDesk
      .then(() => {
        dispatch(fetchChat(topicId, chatType));
      })
      .catch((error) => {
        NotificationManager.error(LanguageProvider.t(TranslationMapper.global.errors.send_chat_error));
        TelemetryService.AppInsights?.trackException({
          exception: {
            name: "ChatActions.sendChat",
            message: `Error on send chat ${error}`,
          },
        });
      })
      .finally(() => dispatch(toggleChatLoadingStatus(false)));
  };
}

export function fetchChatAttachmentSasToken(chatId: string): AppAction {
  return (dispatch, getState): void => {
    const chatService = new ChatService();

    const chatMessages = getState().chatState.chatConversation;
    const chatMessage = chatMessages.find((c) => c.chatId === chatId);

    if (!chatMessage) {
      NotificationManager.error(TranslationMapper.global.errors.general);
      return;
    }

    if (hasValidSasToken(chatMessage)) {
      // No Need to update token
      return;
    }

    chatService
      .getChatAttachmentServiceDeskAsync(chatId)
      .then((u) => {
        dispatch(
          updateChatMessage({
            ...chatMessage,
            attachmentSasUri: u,
            sasTokenExpirationDate: ImageUtil.getSasTokenExpirationDate(),
          })
        );
      })
      .catch((error) => {
        TelemetryService.AppInsights?.trackException({
          exception: {
            name: "ChatActions.fetchChatAttachmentSasTokenServiceDesk",
            message: `Error on fetch chat attachment for chatId '${chatId}' error: '${error}'`,
          },
        });

        NotificationManager.error(i18next.t(TranslationMapper.components.chats.error_fetching_attachment));
      });
  };
}

export function toggleChatLoadingStatus(isLoading: boolean): AppAction {
  return (dispatch): void => {
    dispatch(setChatLoading(isLoading));
  };
}

export function toggleChatsLoadingStatus(isLoading: boolean): AppAction {
  return (dispatch): void => {
    dispatch(setChatsLoading(isLoading));
  };
}

export function toggleChatsDeletionLoadingStatus(isLoading: boolean): AppAction {
  return (dispatch): void => {
    dispatch(setChatsDeletionLoading(isLoading));
  };
}

export function setTopicId(topicId: string | undefined): AppAction {
  return (dispatch): void => {
    dispatch(updateTopicId(topicId));
  };
}

export function fetchChatDeletionCheckDetails(endUserObjectId: string, onError: () => void): AppAction {
  return (dispatch): void => {
    const chatService = new ChatService();

    dispatch(toggleChatsDeletionLoadingStatus(true));
    chatService
      .getChatDeletionCheckDetailsAsync(endUserObjectId)
      .then((deletionDetails) => dispatch(updateChatDeletionCheckDetails(deletionDetails)))
      .catch((error) => {
        dispatch(updateChatDeletionCheckDetails(undefined));
        NotificationManager.error(
          LanguageProvider.t(TranslationMapper.pages.avg_gdpr_management.error_fetch_delete_details)
        );
        onError();
        TelemetryService.AppInsights?.trackException({
          exception: {
            name: "ChatActions.fetchChatDeletionCheckDetails",
            message: `Error on fetch chat deletion details ${error}`,
          },
        });
      })
      .finally(() => dispatch(toggleChatsDeletionLoadingStatus(false)));
  };
}

export function deleteAllChatConversationsForUser(endUserObjectId: string, onSuccess: () => void): AppAction {
  return (dispatch): void => {
    const chatService = new ChatService();

    dispatch(toggleChatsDeletionLoadingStatus(true));
    chatService
      .deleteAllChatConversationsForUserAsync(endUserObjectId)
      .then(() => {
        NotificationManager.success(
          LanguageProvider.t(TranslationMapper.pages.avg_gdpr_management.success_chats_deleted)
        );
        onSuccess();
        dispatch(updateChatDeletionCheckDetails(undefined));
      })
      .catch((error) => {
        dispatch(updateChatDeletionCheckDetails(undefined));
        NotificationManager.error(LanguageProvider.t(TranslationMapper.pages.avg_gdpr_management.error_delete_chats));
        TelemetryService.AppInsights?.trackException({
          exception: {
            name: "ChatActions.deleteAllChatConversationsForUser",
            message: `Error on deleting chats for ${endUserObjectId}: '${error}'`,
          },
        });
      })
      .finally(() => dispatch(toggleChatsDeletionLoadingStatus(false)));
  };
}

function hasValidSasToken(chatMessage: IChatMessage): boolean {
  return (
    chatMessage.attachmentSasUri != null &&
    chatMessage.sasTokenExpirationDate != null &&
    !ImageUtil.hasSasTokenExpired(chatMessage.sasTokenExpirationDate)
  );
}
