import TranslationMapper from "i18n/mapper";
import LanguageProvider from "providers/languageProvider";
import React, { ChangeEvent, Component } from "react";
import { FormControl } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import SendMessageIcon from "../../images/send-message.svg";
import IChatMessage from "../../interfaces/IChatMessage";
import { fetchChat, fetchChatAttachmentSasToken, sendChat } from "../../store/actions/chatActions";
import { RootState } from "../../store/reducers/rootReducer";
import BasicModal from "../material/modal/basicModal";
import ChatInterfaceMessage from "./components/chatInterfaceMessage";
import IChatInterfaceProps, {
  IChatInterfaceDispatchProps,
  IChatInterfaceStateProps,
} from "./interfaces/IChatInterfaceProps";
import IChatInterfaceState from "./interfaces/IChatInterfaceState";

class ChatInterface extends Component<IChatInterfaceProps, IChatInterfaceState> {
  private readonly initialFocusElementRef: React.RefObject<HTMLDivElement>;

  public constructor(props: IChatInterfaceProps) {
    super(props);

    const state: IChatInterfaceState = {
      newMessageContent: "",
    };
    this.state = state;

    this.renderModalFooter = this.renderModalFooter.bind(this);
    this.sendMessage = this.sendMessage.bind(this);
    this.handleOnEntering = this.handleOnEntering.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
    this.onChatMessageChange = this.onChatMessageChange.bind(this);
    this.scrollToLastMessage = this.scrollToLastMessage.bind(this);

    this.initialFocusElementRef = React.createRef();
  }

  public componentDidUpdate(): void {
    if (this.initialFocusElementRef) {
      this.scrollToLastMessage();
    }
  }

  private clearContents(): void {
    this.setState({
      newMessageContent: "",
    });
  }

  private async sendMessage(): Promise<void> {
    if (this.state.newMessageContent) {
      this.props.onSendChat(this.props.topicId, this.state.newMessageContent, this.props.chatType);
      this.clearContents();
    }
  }

  private renderModalFooter(): JSX.Element {
    return (
      <div className="g-chat-footer">
        <FormControl
          maxLength={500}
          placeholder={LanguageProvider.t(TranslationMapper.components.chats.message_placeholder)}
          aria-label="Message"
          className="g-message-input"
          value={this.state.newMessageContent}
          onChange={this.onChatMessageChange}
        ></FormControl>
        <img
          src={SendMessageIcon}
          onClick={this.sendMessage}
          alt="send message"
          className="g-send-message-button"
          data-test="g-send-message-button"
        />
      </div>
    );
  }

  private onChatMessageChange(event: ChangeEvent<HTMLInputElement>): void {
    this.setState({ newMessageContent: event.currentTarget.value });
  }

  private handleOnEntering(): void {
    this.props.onFetchChat(this.props.topicId, this.props.chatType);
  }

  private onCloseModal(): void {
    this.props.onClose();
  }

  private renderChatMessage(message: IChatMessage, index: number): JSX.Element {
    const isLastMessage = this.props.chatConversation.length - 1 === index;
    const onOpenAttachment = message.attachmentUri
      ? (): void => this.props.onOpenChatAttachment(message.chatId)
      : undefined;

    const chatMessage = (
      <div ref={isLastMessage ? this.initialFocusElementRef : undefined} key={index}>
        {this.props.topicId === message.topicId && (
          <ChatInterfaceMessage
            date={message.createdOn instanceof Date ? message.createdOn : new Date(message.createdOn)}
            senderName={message.senderName}
            messageContent={message.contents}
            isFromCurrentUser={this.props.currentUserId === message.senderId}
            attachmentUri={message.attachmentSasUri}
            onOpenAttachment={onOpenAttachment}
          />
        )}
      </div>
    );

    if (this.initialFocusElementRef) {
      this.scrollToLastMessage();
    }

    return chatMessage;
  }

  private scrollToLastMessage(): void {
    if (!this.initialFocusElementRef?.current) {
      return;
    }

    this.initialFocusElementRef.current.scrollIntoView();
  }

  public render(): JSX.Element {
    return (
      <BasicModal
        onEntering={this.handleOnEntering}
        header={LanguageProvider.t(TranslationMapper.components.chats.chat_header)}
        onClose={this.onCloseModal}
        showModal={this.props.showChatInterface}
        className="g-chat-interface"
        footer={this.renderModalFooter()}
        showFullscreen={true}
        isHeaderFixed={true}
        onRefreshChat={this.props.onFetchChat}
        isLoading={this.props.isLoading}
        topicId={this.props.topicId}
        chatType={this.props.chatType}
      >
        {this.props.chatConversation && (
          <div className="g-chat-interface-messages-container">
            {this.props.chatConversation.map((c: IChatMessage, index: number) => this.renderChatMessage(c, index))}
          </div>
        )}
      </BasicModal>
    );
  }
}

const mapStateToProps = (state: RootState): IChatInterfaceStateProps => ({
  chatConversation: state.chatState.chatConversation,
  isLoading: state.chatState.isLoading,
  currentUserId: state.userState.user?.userId,
});

const mapDispatchToProps: IChatInterfaceDispatchProps = {
  onFetchChat: fetchChat,
  onSendChat: sendChat,
  onOpenChatAttachment: fetchChatAttachmentSasToken,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ChatInterface));
